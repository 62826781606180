import styled from 'styled-components';
import styleVariables from '../constants/styleVariables';

const HomeStyle = styled.div`
  #index-landing-page {
    & .index-landing-page-background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index: -1;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .index-landing-page-font-wrapper {
      padding-top: calc(50vh - 80px);
      height: 75vh;

      & .index-landing-page-font-container {
        & .index-landing-page-slide {
          position: absolute;
          transition: left ease 0.4s, right ease 0.4s, opacity ease 0.2s;
          opacity: 0;
          left: 6vw;
          right: 10vw;

          &.active {
            opacity: 1;
            left: 8vw;
            right: 8vw;
          }
        }

        & .index-langing-page-font-container-heading {
          font-weight: 900;
          font-size: 64px;
          line-height: 77px;
          color: ${styleVariables.white};
        }

        & .index-langing-page-font-container-text {
          margin-top: 20px;
          font-size: 28px;
          line-height: 40px;
          color: ${styleVariables.white};
          font-weight: 300;
        }
      }

      & .index-landing-page-font-container-chooser {
        padding-top: 233px;

        & .index-landing-page-chooser {
          float: left;
          margin-right: 23px;
          color: ${styleVariables.white};
          font-weight: 500;
          opacity: 0.6;
          padding-bottom: 12px;
          font-size: 14px;
          line-height: 17px;
          cursor: pointer;

          &.active {
            cursor: inherit;
            opacity: 1;
            border-bottom: solid 2px ${styleVariables.darkRed};
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    & #index-landing-page-show-more {
      height: 25vh;
      position: relative;

      & div {
        right: calc(8vw - 72px);
        top: 76px;
        color: ${styleVariables.white};
        transition: top ease ${styleVariables.defaultTransitionDuration};

        &:hover {
          top: 81px;
        }
      }
    }
  }

  #index-intro-section {
    margin-top: 80px;

    & .index-intro-container {
      position: relative;
      height: 580px;

      & img {
        top: 0;
        position: absolute;
        right: 0;
        width: calc(100% - 460px);
        z-index: -1;
        height: 100%;
        object-fit: cover;
      }

      & h1 {
        &:first-of-type {
          padding-top: 48px;
        }

        &:last-of-type {
          font-weight: 300;
        }
      }

      & p {
        width: 440px;
        padding-left: 35px;
        padding-top: 24px;
      }

      & a {
        bottom: 0;
        padding-left: 35px;
        position: absolute;

        & hr {
          background-color: ${styleVariables.darkRed};
          height: 2px;
          margin: 8px 0 0;
          width: 0;
          transition: width ease ${styleVariables.defaultTransitionDuration};
        }

        &:hover hr {
          width: 100%;
        }
      }
    }
  }

  .index-projects-section {
    padding-top: 168px;

    & .index-h1 {
      display: none;
    }

    .index-projects-links {
      margin-top: 32px;

      & .index-projects-links-item {
        border: 10px solid ${styleVariables.lightGrey};
        height: 218px;
        position: relative;

        & h4 {
          margin-top: 56px;
        }

        & .index-projects-links-item-link {
          position: absolute;
          top: 134px;
          width: 100%;
          text-align: center;

          & div {
            height: 28px;
            display: inline-block;

            & a {
              height: 28px;
              position: relative;

              & hr {
                pointer-events: none;
                background-color: ${styleVariables.darkRed};
                height: 2px;
                margin: 8px 0 0;
                width: 0;
                transition: margin-left ease
                    ${styleVariables.defaultTransitionDuration},
                  width ease ${styleVariables.defaultTransitionDuration};
              }

              &:hover hr {
                margin-left: 7px;
                width: 100%;
              }
            }

            & a:hover {
              margin-left: 10px;
            }
          }
        }

        &:last-of-type {
          margin-top: 32px;
        }
      }
    }
  }

  .index-partners-section {
    margin-top: 200px;

    & .index-partners-container {
      position: relative;

      & p {
        width: 374px;
        margin-top: 24px;
        margin-left: 32px;
      }

      & .index-partners-rectangle {
        border: 10px solid ${styleVariables.lightGrey};
        position: absolute;
        height: 335px;
        left: 65px;
        right: 260px;
        top: 21px;
        z-index: -1;
      }

      & .index-partners-logos {
        position: absolute;
        right: 310px;
        top: 113px;
        left: 440px;

        & img {
          width: 100%;
          max-width: 100px;
          height: auto;
          margin-bottom: 48px;
        }
      }
    }
  }

  #index-contact-section {
    margin-top: 198px;

    & .index-contact-container {
      position: relative;

      & .index-contact-rectangle {
        border: 10px solid ${styleVariables.lightGrey};
        position: absolute;
        left: 64px;
        top: 21px;
        right: 260px;
        height: 208px;
        z-index: -1;
      }

      & .index-contact-box {
        margin-left: 130px;
        background-color: ${styleVariables.white};
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
        padding: 32px;
        height: auto;

        & img {
          float: left;
          width: 150px;
          height: 171px;
          object-fit: cover;
        }

        & .index-contact-box-text {
          padding-left: 174px;

          & .index-contact-box-text-heading {
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 31px;
            font-weight: 700;
            margin-top: 8px;
          }

          & .index-contact-box-text-links {
            margin-top: 8px;
            margin-bottom: 4px;
          }

          & a {
            font-size: 14px;
            line-height: 16px;
            color: ${styleVariables.black};
          }
        }
      }
    }
  }
  @media screen and (max-height: 690px) {
    #index-landing-page {
      & #index-landing-page-show-more {
        & div {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    #index-landing-page {
      & .index-landing-page-background {
        top: ${styleVariables.headerHeight};
        height: 375px;
      }

      & .index-landing-page-font-wrapper {
        padding-top: calc(${styleVariables.headerHeight} + 50px);
        height: calc(${styleVariables.headerHeight} + 375px);

        & .index-landing-page-font-container {
          & .index-langing-page-font-container-heading {
            font-size: 24px;
            letter-spacing: 2px;
            line-height: 29px;
          }

          & .index-langing-page-font-container-text {
            font-size: 18px;
            line-height: 32px;
          }
        }

        & .index-landing-page-font-container-chooser {
          padding-top: 270px;
        }
      }

      & #index-landing-page-show-more {
        display: none;
      }
    }

    #index-intro-section {
      & .index-intro-container {
        & img {
          position: inherit;
          margin-top: 40px;
          width: 100%;
          height: 262px;
        }

        & h1 {
          &:first-of-type {
            padding-top: 0;
          }
        }

        & p {
          width: 100%;
          padding-left: 0;
          padding-top: 18px;
          padding-bottom: 32px;
        }

        & a {
          position: inherit;
          padding-left: 0;
        }
      }
    }

    .index-partners-section {
      margin-top: 80px;

      & .index-partners-container {
        position: relative;

        & p {
          width: 100%;
          margin-top: 16px;
          margin-left: 0;
        }

        & .index-partners-rectangle {
          display: none;
        }

        & .index-partners-logos {
          position: inherit;
          left: 0;
          right: 0;
          top: 64px;
          bottom: 0;

          & img {
            max-width: 150px;
          }
        }
      }
    }

    #index-contact-section {
      margin-top: 80px;

      & .index-contact-container {
        position: relative;

        & .index-contact-rectangle {
          display: none;
        }

        & .index-contact-box {
          margin-left: 0;
          background-color: transparent;
          box-shadow: none;
          padding: 0;

          & img {
            float: none;
            margin-top: 16px;
          }

          & .index-contact-box-text {
            padding-left: 0;

            & .index-contact-box-text-heading {
              font-size: 21px;
              line-height: 24px;
              margin-top: 4px;
            }

            & .index-contact-box-text-links {
              margin-top: 16px;
            }

            & .index-contact-box-text-leader {
              margin-top: 24px;
            }

            & a {
              font-size: 14px;
              line-height: 16px;
              color: ${styleVariables.black};
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .index-partners-section {
      & .index-partners-container {
        & .index-partners-rectangle {
          right: 0;
        }

        & .index-partners-logos {
          right: 80px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .index-projects-section {
      & .index-h1 {
        display: inherit;
      }
      padding-top: 230px;

      .index-projects-links {
        display: none;
      }
    }
  }
`;

export default HomeStyle;
