import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import routes from './constants/routes';
import logoBlack from '../assets/svg/logo_black.svg';
import logoWhite from '../assets/svg/logo_white.svg';
import menuClose from '../assets/svg/menu-close.svg';
import menuBlack from '../assets/svg/menu_black.svg';
import menuWhite from '../assets/svg/menu_white.svg';
import HeaderStyle from './HeaderStyle';

const openHeaderClassName = 'header-menu-button header-menu-button-open';
const closeHeaderClassName = 'header-menu-button';

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [positionTop, setPositionTop] = useState(true);
  const projectsRoute = useRouteMatch('/projekte');
  const projectRoute = useRouteMatch('/projekte/:projectId');
  const impressumRoute = useRouteMatch('/impressum');
  const { pathname } = useLocation();

  const listenScrollEvent = () => {
    setPositionTop(window.scrollY < 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  }, []);

  const headerClasses = () => {
    if (positionTop && !projectsRoute && !projectRoute && !impressumRoute) {
      if (pathname !== '/') {
        return 'header-white';
      }
      return 'header-white header-white-special';
    }
    return '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logoClicked = () => {
    setMobileMenuOpen(false);
    if (pathname === '/') {
      setTimeout(() => {
        const element = document.getElementById('index-landing-page');
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 200);
    }
  };

  return (
    <HeaderStyle
      className={mobileMenuOpen ? `${headerClasses()} menu-open` : headerClasses()}
    >
      <div className="header-container oliverbeer-container-without-maxwidth">
        <div className="header-item">
          <Link to="/" onClick={logoClicked}>
            <img src={logoBlack} className="header-logo" alt="Zimmerei Beer Logo" />
            <img src={logoWhite} className="header-logo" alt="Zimmerei Beer Logo" />
          </Link>
        </div>
        <div className="header-item header-item-large align-right">
          <div id="index-navigation">
            {routes.map((route, index) => (
              <Link
                className={`index-navigation-item item${index + 1}`}
                to={route.path}
                title={route.name}
                key={route.path}
              >
                {route.name}
              </Link>
            ))}
            <Link
              className="index-navigation-item item4 header-navigation-contact"
              to="/#kontakt"
              title="Kontakt"
            >
              Kontakt
            </Link>
            <hr />
          </div>
        </div>
        <div className="header-item header-item-large align-right">
          <a
            id="index-cantact-button"
            href="mailto:office@zimmerei-beer.at?subject=Projektanfrage"
          >
            <span>Mail schreiben</span>
          </a>
        </div>
        <div className="header-item header-item-small align-right">
          <div
            onClick={() => setMobileMenuOpen(true)}
            className={
              mobileMenuOpen
                ? `${openHeaderClassName} invisible`
                : openHeaderClassName
            }
          >
            <img src={menuBlack} alt="Menu öffnen" />
            <img src={menuWhite} alt="Menu öffnen" />
          </div>
          <div
            onClick={() => setMobileMenuOpen(false)}
            className={
              !mobileMenuOpen
                ? `${closeHeaderClassName} invisible`
                : closeHeaderClassName
            }
          >
            <img src={menuClose} alt="Menu schließen" />
          </div>
          <div id="header-menu" className={mobileMenuOpen ? 'active' : ''}>
            {routes.map(route => (
              <div key={route.path}>
                <Link
                  to={route.path}
                  title={route.name}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {route.name}
                </Link>
              </div>
            ))}
            <div>
              <Link
                className="header-navigation-contact"
                to="/#kontakt"
                title="Kontakt"
                onClick={() => setMobileMenuOpen(false)}
              >
                Kontakt
              </Link>
            </div>
          </div>
        </div>
      </div>
    </HeaderStyle>
  );
}

export default Header;
