import React from 'react';
import { Col, Row } from 'react-bootstrap';
import wohnbauImage from '../../../assets/img/services/wohnbau.jpg';
import gewerbebauImage from '../../../assets/img/services/gewerbebau.jpg';

function ServicesMainSection1() {
  return (
    <section className="services-main-section">
      <div className="oliverbeer-container">
        <Row className="row">
          <Col md={6}>
            <div className="services-main-item" id="services-main-section-1">
              <div className="services-main-item-img image-container">
                <img src={wohnbauImage} alt="Wohnbau" />
              </div>
              <div className="services-main-item-rectangle" />
              <h2>Wohnbau</h2>
              <p>
                Sie fühlen sich zuhause – von der ersten Sekunde an! Hier sind wir
                uns sicher, denn jeder Wunsch wird besprochen und sofern irgend
                möglich auch umgesetzt. Wir bauen gemeinsam.
              </p>
              <div className="services-main-item-number">01</div>
            </div>
          </Col>
          <Col md={6}>
            <div className="services-main-item" id="services-main-section-2">
              <div className="services-main-item-img image-container">
                <img src={gewerbebauImage} alt="Gewerbebau" />
              </div>
              <div className="services-main-item-rectangle" />
              <h2>Gewerbebau</h2>
              <p>
                Ein Arbeitsplatz, an dem sich alle wohlfühlen. Einer, der klug
                geplant ist und bei dem der vorhandene Platz bestmöglich genutzt
                wird. Wir von der Zimmerei Beer machen es möglich.
              </p>
              <div className="services-main-item-number">02</div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ServicesMainSection1;
