import React from 'react';
import AboutStyle from './AboutStyle';
import AboutHeaderSection from './components/AboutHeaderSection';
import AboutImageSection from './components/AboutImageSection';
import AboutEmployees from './components/AboutEmployees';

function About() {
  return (
    <AboutStyle>
      <AboutHeaderSection />
      <AboutImageSection />
      <AboutEmployees />
    </AboutStyle>
  );
}

export default About;
