import React from 'react';
import aboutImage from '../../../assets/img/aboutus_background.jpg';
import { ScrollBlack } from '../../constants/styleComponents';

function AboutHeaderSection() {
  return (
    <section className="aboutus-top-section">
      <div className="aboutus-top-img-container image-container">
        <img src={aboutImage} alt="Über uns" />
      </div>
      <div className="aboutus-top-info-container">
        <label>Wer sind wir</label>
        <h1>Über uns</h1>
        <div className="aboutus-top-info-line" />
        <ScrollBlack
          to="aboutus-intro-section"
          className="aboutus-top-more"
          animate={{ offset: -150 }}
        >
          <span>Erfahre mehr über uns</span>
        </ScrollBlack>
      </div>
    </section>
  );
}

export default AboutHeaderSection;
