import styled from 'styled-components';
import styleVariables from '../constants/styleVariables';

const ProjectsStyle = styled.div`
  .projects-header-section {
    margin-top: 212px;

    & p {
      line-height: 36px;
      font-weight: 300;
      width: 50%;
    }
  }

  .projects-selector-desktop {
    margin-top: 64px;

    & label {
      margin-left: 32px;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 17px;
      color: ${styleVariables.black};
      opacity: 0.3;
      cursor: pointer;
      padding-bottom: 5px;
      transition: color ease ${styleVariables.defaultTransitionDuration},
        opacity ease ${styleVariables.defaultTransitionDuration};

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        color: ${styleVariables.darkRed};
        opacity: 1;
      }

      &.active {
        color: ${styleVariables.darkRed};
        opacity: 1;
        border-bottom: solid ${styleVariables.darkRed} 2px;
      }
    }
  }

  .project-container {
    cursor: pointer;
    margin-top: 32px;
    height: 315px;
    position: relative;

    &.index-projects-container {
      height: 468px;

      & h3 {
        font-size: 28px;
        letter-spacing: 2px;
        line-height: 33px;
        top: 54px;
      }

      & .projects-item-link {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 17px;
        bottom: 35px;
      }

      & .project-container-shadow {
        height: 145px;
      }

      &:hover {
        & .project-container-shadow {
          height: 200px;
        }
      }
    }

    & img {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & label {
      position: absolute;
      top: 24px;
      left: 24px;
    }

    & h3 {
      position: absolute;
      top: 48px;
      left: 24px;
    }

    & .projects-item-link {
      position: absolute;
      bottom: 24px;
      color: ${styleVariables.white};
      padding-left: 24px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0;

      & hr {
        background-color: ${styleVariables.white};
        height: 2px;
        margin: 8px 0 0;
        width: 0;
        transition: width ease ${styleVariables.defaultTransitionDuration};
      }

      &:hover hr {
        width: 100%;
      }
    }

    & .project-container-shadow {
      position: absolute;
      height: 100px;
      width: 100%;
      z-index: 0;
      transition: height ease 0.2s;

      &.project-container-shadow-top {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        transform: rotate(180deg);
        top: 0;
      }

      &.project-container-shadow-bottom {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        bottom: 0;
      }
    }

    &:hover {
      & .project-container-shadow {
        height: 150px;
      }
    }
  }

  .projects-selector-mobile {
    display: none;
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;

    #projects-selector-mobile-button {
      cursor: pointer;
      border-top: 1px solid ${styleVariables.grey};
      border-bottom: 1px solid ${styleVariables.grey};
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 17px;
      text-align: center;
      color: ${styleVariables.black};
      font-weight: 300;
      height: 72px;
      text-transform: uppercase;
      padding-top: 27px;

      & #projects-selector-mobile-button-close {
        display: none;
      }

      & svg {
        margin: 0 8px 2px 0;
        transform: rotate(-180deg);
        transition: transform ease ${styleVariables.defaultTransitionDuration},
          margin ease ${styleVariables.defaultTransitionDuration};
      }

      &.active {
        & #projects-selector-mobile-button-close {
          display: inline;
        }

        & #projects-selector-mobile-button-open {
          display: none;
        }

        & svg {
          transform: rotate(0);
          margin: 0 8px 0 0;
        }
      }
    }

    & #projects-selector-mobile-container {
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      bottom: 72px;
      left: 0;
      right: 0;
      background-color: ${styleVariables.white};

      & div {
        margin: 20px 24px 0;

        & label {
          cursor: pointer;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 19px;
          text-align: center;
          width: 100%;
          padding: 16px;
          border: 1px solid #cccccc;
          transition: border ease ${styleVariables.defaultTransitionDuration};

          &.active {
            color: ${styleVariables.darkRed};
            border: 1px solid ${styleVariables.darkRed};
          }

          &:hover {
            border: 1px solid ${styleVariables.darkRed};
          }
        }
        transition: margin ease ${styleVariables.defaultTransitionDuration};

        &:last-of-type {
          margin-bottom: 20px;
        }
      }

      &.active {
        opacity: 1;
        pointer-events: all;

        & div {
          margin: 24px 24px 0;

          &:last-of-type {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .projects-header-section {
      margin-top: 174px;

      & p {
        line-height: 30px;
        width: 100%;
      }
    }

    .projects-selector-desktop {
      display: none;
    }

    .project-container {
      margin-top: 24px;
      height: 250px;

      &.index-projects-container {
        height: 250px;

        & h3 {
          top: 38px;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 19px;
        }

        & .projects-item-link {
          bottom: 16px;
          padding-left: 16px;
        }

        & .project-container-shadow {
          height: 80px;
        }

        &:hover {
          & .project-container-shadow {
            height: 120px;
          }
        }
      }

      & label {
        top: 16px;
        left: 16px;
      }

      & h3 {
        top: 38px;
        left: 16px;
      }

      & .projects-item-link {
        bottom: 16px;
        padding-left: 16px;
      }

      & .project-container-shadow {
        height: 80px;
      }

      &:hover {
        & .project-container-shadow {
          height: 120px;
        }
      }
    }

    .projects-selector-mobile {
      display: inherit;
    }
  }
`;

export default ProjectsStyle;
