import React from 'react';
import { Row } from 'react-bootstrap';
import waelderInstallateur from '../../../assets/img/partner/waelderinstallateur.png';
import stipoFussboeden from '../../../assets/img/partner/stipo.png';
import kaufmannBausysteme from '../../../assets/img/partner/kaufmannbausysteme.png';
import dachdeckerMeister from '../../../assets/img/partner/dachdeckermeister.png';

function PartnersSection() {
  return (
    <section className="index-partners-section">
      <div className="oliverbeer-container">
        <div className="index-partners-container">
          <h2>Partner</h2>
          <p>
            Um unseren Kunden den bestmöglichen Service und ausgezeichnete Qualität
            bieten zu können, arbeiten wir eng mit verlässlichen Partnern aus der
            Region zusammen.
          </p>
          <div className="index-partners-rectangle" />
          <div className="index-partners-logos">
            <Row>
              <div className="col-5 align-center">
                {/* eslint-disable-next-line */}
                <a href="http://www.waeldarinstallateur.at/" target="_blank">
                  <img src={waelderInstallateur} alt="Waelder Installateur" />
                </a>
              </div>
              <div className="col-2" />
              <div className="col-5 align-center">
                {/* eslint-disable-next-line */}
                <a href="http://www.stipo-fussboeden.at/" target="_blank">
                  <img src={stipoFussboeden} alt="Stipo Fußböden" />
                </a>
              </div>
              <div className="col-5 align-center">
                {/* eslint-disable-next-line */}
                <a href="http://www.kaufmannbausysteme.at/" target="_blank">
                  <img src={kaufmannBausysteme} alt="Kaufmann Bausysteme" />
                </a>
              </div>
              <div className="col-2" />
              <div className="col-5 align-center">
                {/* eslint-disable-next-line */}
                <a href="http://www.ddm-lang.at/" target="_blank">
                  <img src={dachdeckerMeister} alt="Dachdecker Meister" />
                </a>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnersSection;
