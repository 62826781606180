import React from 'react';
import ProjectsStyle from './ProjectsStyle';
import ProjectsHeaderSection from './components/ProjectsHeaderSection';
import ProjectsMainSection from './components/ProjectsMainSection';

function Projects() {
  return (
    <ProjectsStyle>
      <ProjectsHeaderSection />
      <ProjectsMainSection />
    </ProjectsStyle>
  );
}

export default Projects;
