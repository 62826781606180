import React, { useState } from 'react';
import {
  ArrowImageGalleryRight,
  ArrowImageGalleryLeft,
  ArrowRightBlack,
} from '../../constants/svg';
import { ScrollBlack } from '../../constants/styleComponents';

function ProjectImageSliderSection({ project }) {
  const [active, setActive] = useState(1);

  const factor = window.innerWidth > 991 ? 25 : 0;

  const left = active === 0 ? '25%' : `-${factor * active - factor}%`;
  const images = project.bilder;
  return (
    <section id="project-images-section">
      <div className="oliverbeer-container" style={{ position: 'relative' }}>
        <div className="project-images-container">
          <div className="project-sub-heading">Bildergalerie</div>
          <div className="project-rectangle"></div>
          <div className="project-images-gallery-container">
            <div
              className="project-images-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
              style={{ left }}
            >
              {images.slice(0, 9).map((image, index) => (
                <figure
                  itemProp="associatedMedia"
                  itemScope="itemScope"
                  itemType="http://schema.org/ImageObject"
                  className={
                    active === index ? 'image-container active' : 'image-container'
                  }
                  key={index}
                >
                  <img
                    src={image.bild.url}
                    itemProp="thumbnail"
                    alt="Projects pic"
                  />
                </figure>
              ))}
            </div>

            <div className="project-images-gallery-legend">
              {images.slice(0, 9).map((image, index) => (
                <div key={index} className={active === index ? 'active' : ''} />
              ))}
            </div>

            <div className="project-images-gallery-controls">
              <div
                className={active === 0 ? 'disabled' : ''}
                onClick={() => setActive(active - 1)}
              >
                <ArrowImageGalleryLeft />
              </div>
              <div
                className={active === images.length - 1 ? 'disabled' : ''}
                onClick={() => setActive(active + 1)}
              >
                <ArrowImageGalleryRight />
              </div>
            </div>
          </div>
          <div
            id="project-images-to-more-projects"
            className="global-go-down-button"
          >
            <ScrollBlack
              to="project-more-projects-section"
              animate={{ offset: -150 }}
            >
              Mehr Projekte
              <ArrowRightBlack />
            </ScrollBlack>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectImageSliderSection;
