import React from 'react';
import ServicesStyle from './ServicesStyle';
import ServicesHeaderSection from './components/ServicesHeaderSection';
import ServicesMainSection1 from './components/ServicesMainSection1';
import ServicesMainSection2 from './components/ServicesMainSection2';

function Services() {
  return (
    <ServicesStyle>
      <ServicesHeaderSection />
      <ServicesMainSection1 />
      <ServicesMainSection2 />
    </ServicesStyle>
  );
}

export default Services;
