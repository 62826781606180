import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RichText } from 'prismic-dom';
import { Col, Row } from 'react-bootstrap';
import { QUERY_EMPLOYEES } from '../../constants/queries';
import LoadingIndicator from '../../constants/LoadingIndicator';

function AboutEmployees() {
  const { loading, data, error } = useQuery(QUERY_EMPLOYEES);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const employees = data.employees.edges;
  return (
    <section className="aboutus-employees-section">
      <div className="oliverbeer-container">
        <div className="aboutus-employees-container">
          <h2>Unser Team</h2>
          <div className="aboutus-employee-rectangle" />
          {employees.map((employee, index) => (
            <div className="aboutus-employee-item" key={employee.node.meta.uid}>
              <Row>
                <Col md={4}>
                  <div className="aboutus-employee-img image-container">
                    <img
                      src={employee.node.bild.url}
                      alt={RichText.asText(employee.node.name)}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="aboutus-employee-info">
                    <label>{RichText.asText(employee.node.job_titel)}</label>
                    <h3>{RichText.asText(employee.node.name)}</h3>

                    {index === 0 && (
                      <>
                        <label>Telefon:</label>
                        <a href="tel:+436645457667">
                          <p>+43 664 5457667</p>
                        </a>
                        <label>E-Mail:</label>
                        <a href="mailto:office@zimmerei-beer.at?subject=Projektanfrage">
                          <p>office@zimmerei-beer.at</p>
                        </a>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AboutEmployees;
