import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import client from './client';
import Home from './components/home/Home';
import Header from './components/Header';
import GlobalStyle from './GlobalStyle';
import Footer from './components/Footer';
import Projects from './components/projects/Projects';
import Services from './components/services/Services';
import About from './components/about/About';
import Project from './components/project/Project';
import Impressum from './components/impressum/Impressum';

function App() {
  return (
    <div className="App">
      <GlobalStyle>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Header />
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/projekte" exact>
                <Projects />
              </Route>
              <Route path="/leistungen" exact>
                <Services />
              </Route>
              <Route path="/ueber-uns" exact>
                <About />
              </Route>
              <Route path="/projekte/:projectId" exact>
                <Project />
              </Route>
              <Route path="/impressum" exact>
                <Impressum />
              </Route>
              <Route>
                <section className="default-section">
                  <div className="oliverbeer-container">
                    <h2>404 - Seite nicht gefunden</h2>
                    <Link to="/">Zurück zur Startseite</Link>
                  </div>
                </section>
              </Route>
            </Switch>
            <Footer />
          </BrowserRouter>
        </ApolloProvider>
      </GlobalStyle>
    </div>
  );
}

export default App;
