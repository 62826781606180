import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import contactImage from '../../../assets/img/contact.jpg';
import { useRouteMatch } from 'react-router-dom';

function ContactSection() {
  const match = useRouteMatch('/');

  useEffect(() => {
    if (match && window.location.hash === '#kontakt') {
      const element = document.getElementById('index-contact-section');
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [match]);

  return (
    <section id="index-contact-section">
      <div className="oliverbeer-container">
        <Row>
          <Col lg={5} />
          <Col lg={7}>
            <div className="index-contact-container">
              <h2>Kontakt</h2>
              <div className="index-contact-rectangle" />
              <div className="index-contact-box">
                <img src={contactImage} alt="Oliver Beer" />
                <div className="index-contact-box-text">
                  <Row>
                    <div className="col-12">
                      <label className="index-contact-box-text-leader">
                        Geschäftsführer
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="index-contact-box-text-heading">
                        Oliver Beer
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="index-contact-box-text-links">
                        <label>Telefon:</label>
                        <br />
                        <a href="tel:+436645457667">+43 664 5457667</a>
                      </div>
                    </div>
                    <div className="col-12">
                      <label>E-Mail:</label>
                      <br />
                      <a href="mailto:office@zimmerei-beer.at?subject=Projektanfrage">
                        office@zimmerei-beer.at
                      </a>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ContactSection;
