import React, { useState } from 'react';
import helpers from '../../constants/helpers';
import landingImage from '../../../assets/img/startpage.jpg';
import { ArrowRightWhite } from '../../constants/svg';
import { ScrollWhite } from '../../constants/styleComponents';

const strings = {
  welcomeHeading1: 'Tradition & Innovation',
  welcomeHeading2: 'Holz',
  welcomeHeading3: 'Nachhaltigkeit',
  welcomeText1:
    'Durch den Baustoff Holz lassen sich modernste Architektur und altbewährte Tradition auf wundervolle Art und Weise vereinen.',
  welcomeText2:
    'Holz ist aufgrund seiner Beschaffenheit ein hervorragender Wärmeschutz, im Winter als auch im Sommer. So sparen Sie Energiekosten.',
  welcomeText3:
    'Holz ist ein nachhaltiger Rohstoff. Jede Sekunde wächst im Wald ein Kubikmeter Holz nach.',
};

const landingPageSlide = 'index-landing-page-slide';
const landingPageChoooser = 'index-landing-page-chooser';

function LandingSection() {
  const [activeItem, setActiveItem] = useState(0);

  const isActiveSlide = index =>
    activeItem === index ? `${landingPageSlide} active` : landingPageSlide;
  const isActiveChooser = index =>
    activeItem === index ? `${landingPageChoooser} active` : landingPageChoooser;

  helpers.useInterval(() => {
    let i = activeItem + 1;
    if (i === 3) {
      i = 0;
    }
    setActiveItem(i);
  }, 4000);

  return (
    <section id="index-landing-page">
      <div className="index-landing-page-background image-container">
        <img src={landingImage} alt="Zimmerei Beer" />
      </div>

      <div className="oliverbeer-container-without-maxwidth">
        <div className="index-landing-page-font-wrapper">
          <div className="index-landing-page-font-container">
            <div className={isActiveSlide(0)} id="index-landing-page-slide1">
              <div className="index-langing-page-font-container-heading">
                {strings.welcomeHeading1}
              </div>
              <div className="index-langing-page-font-container-text">
                {strings.welcomeText1}
              </div>
            </div>
            <div className={isActiveSlide(1)} id="index-landing-page-slide2">
              <div className="index-langing-page-font-container-heading">
                {strings.welcomeHeading2}
              </div>
              <div className="index-langing-page-font-container-text">
                {strings.welcomeText2}
              </div>
            </div>
            <div className={isActiveSlide(2)} id="index-landing-page-slide3">
              <div className="index-langing-page-font-container-heading">
                {strings.welcomeHeading3}
              </div>
              <div className="index-langing-page-font-container-text">
                {strings.welcomeText3}
              </div>
            </div>
          </div>
          <div className="index-landing-page-font-container-chooser">
            <div className={isActiveChooser(0)} onClick={() => setActiveItem(0)}>
              01
            </div>
            <div className={isActiveChooser(1)} onClick={() => setActiveItem(1)}>
              02
            </div>
            <div className={isActiveChooser(2)} onClick={() => setActiveItem(2)}>
              03
            </div>
          </div>
        </div>
      </div>

      <div id="index-landing-page-show-more">
        <div className="global-go-down-button">
          <ScrollWhite to="index-intro-section" animate={{ offset: -150 }}>
            Mehr erfahren
            <ArrowRightWhite />
          </ScrollWhite>
        </div>
      </div>
    </section>
  );
}

export default LandingSection;
