import React from 'react';

export const ArrowRightWhite = () => (
  <svg width="20px" height="9px" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="arrow-red" fillRule="nonzero" fill="#FFFFFF">
        <path
          d="M10.7366935,6.59053106 L10.7366935,-5 L9.42004395,-5 L9.42004395,6.60178935 C9.08136847,6.5999108 8.74768423,6.59273084 8.43628442,6.57640946 C7.6949732,6.53755517 6.88287839,6.38310244 6,6.11305127 C7.05093326,7.89220564 7.89555931,9.34119588 8.53387817,10.460022 C9.17219702,11.5788481 9.71147212,12.7259576 10.1517035,13.9013506 C10.0430165,13.6609884 10.5745619,12.5138789 11.7463397,10.460022 C12.9181175,8.40616507 13.7715721,6.95717483 14.3067037,6.11305127 C13.2556363,6.42195673 12.4324802,6.57640946 11.8372357,6.57640946 C11.5216647,6.57640946 11.1432294,6.58368978 10.7366935,6.59053106 Z"
          id="arrow"
          transform="translate(10.153352, 4.450675) rotate(-90.000000) translate(-10.153352, -4.450675) "
        />
      </g>
    </g>
  </svg>
);

export const ArrowRightRed = () => (
  <svg width="20px" height="9px" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="arrow-red" fillRule="nonzero" fill="#B4181E">
        <path
          d="M10.7366935,6.59053106 L10.7366935,-5 L9.42004395,-5 L9.42004395,6.60178935 C9.08136847,6.5999108 8.74768423,6.59273084 8.43628442,6.57640946 C7.6949732,6.53755517 6.88287839,6.38310244 6,6.11305127 C7.05093326,7.89220564 7.89555931,9.34119588 8.53387817,10.460022 C9.17219702,11.5788481 9.71147212,12.7259576 10.1517035,13.9013506 C10.0430165,13.6609884 10.5745619,12.5138789 11.7463397,10.460022 C12.9181175,8.40616507 13.7715721,6.95717483 14.3067037,6.11305127 C13.2556363,6.42195673 12.4324802,6.57640946 11.8372357,6.57640946 C11.5216647,6.57640946 11.1432294,6.58368978 10.7366935,6.59053106 Z"
          id="arrow"
          transform="translate(10.153352, 4.450675) rotate(-90.000000) translate(-10.153352, -4.450675) "
        />
      </g>
    </g>
  </svg>
);

export const ArrowRightBlack = () => (
  <svg
    width="20px"
    height="9px"
    viewBox="0 0 20 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="arrow-black" fillRule="nonzero" fill="#333333">
        <path
          d="M10.7366935,6.59053106 L10.7366935,-5 L9.42004395,-5 L9.42004395,6.60178935 C9.08136847,6.5999108 8.74768423,6.59273084 8.43628442,6.57640946 C7.6949732,6.53755517 6.88287839,6.38310244 6,6.11305127 C7.05093326,7.89220564 7.89555931,9.34119588 8.53387817,10.460022 C9.17219702,11.5788481 9.71147212,12.7259576 10.1517035,13.9013506 C10.0430165,13.6609884 10.5745619,12.5138789 11.7463397,10.460022 C12.9181175,8.40616507 13.7715721,6.95717483 14.3067037,6.11305127 C13.2556363,6.42195673 12.4324802,6.57640946 11.8372357,6.57640946 C11.5216647,6.57640946 11.1432294,6.58368978 10.7366935,6.59053106 Z"
          id="arrow"
          transform="translate(10.153352, 4.450675) rotate(-90.000000) translate(-10.153352, -4.450675) "
        ></path>
      </g>
    </g>
  </svg>
);

export const ArrowUpBlack = () => (
  <svg
    width="12px"
    height="6px"
    viewBox="0 0 12 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="MobileFilterOverlay"
        transform="translate(-110.000000, -433.000000)"
        fill="#333333"
      >
        <g id="open-filters-button" transform="translate(-7.000000, 400.000000)">
          <g id="filter-text-arrow" transform="translate(117.000000, 27.000000)">
            <polygon
              id="Rectangle-17"
              transform="translate(6.000000, 6.000000) rotate(-315.000000) translate(-6.000000, -6.000000) "
              points="10 2 10 10 2 10"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowImageGalleryLeft = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop1440_UeberUns" transform="translate(-984.000000, -1397.000000)">
        <g id="gallery-controls" transform="translate(984.000000, 1397.000000)">
          <g
            id="gallery-control-previous"
            transform="translate(20.000000, 20.000000) rotate(-180.000000) translate(-20.000000, -20.000000) "
          >
            <g id="last-image-inactive">
              <rect
                id="Rectangle-9"
                fill="#333333"
                x="0"
                y="0"
                width="40"
                height="40"
                rx="5"
              />
              <path
                d="M20.7259648,21.8456267 L20.7259648,12.9211815 L19.7121746,12.9211815 L19.7121746,21.8542953 C19.4514022,21.8528489 19.1944729,21.8473205 18.9547021,21.8347534 C18.3839093,21.8048365 17.7586148,21.6859114 17.0788185,21.4779781 C17.8880132,22.8478865 18.5383561,23.963576 19.0298471,24.8250467 C19.5213381,25.6865173 19.9365676,26.5697656 20.2755358,27.4747914 C20.1918492,27.289718 20.6011271,26.4064698 21.5033694,24.8250467 C22.4056116,23.2436236 23.0627523,22.1279341 23.4747914,21.4779781 C22.6654934,21.7158283 22.031682,21.8347534 21.5733572,21.8347534 C21.3303748,21.8347534 21.0389882,21.8403591 20.7259648,21.8456267 Z"
                id="arrow"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform="translate(20.276805, 20.197986) rotate(-90.000000) translate(-20.276805, -20.197986) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowImageGalleryRight = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop1440_UeberUns" transform="translate(-1040.000000, -1397.000000)">
        <g id="gallery-controls" transform="translate(984.000000, 1397.000000)">
          <g id="gallery-control-next" transform="translate(56.000000, 0.000000)">
            <g id="next-image-active">
              <rect
                id="Rectangle-9"
                fill="#333333"
                x="0"
                y="0"
                width="40"
                height="40"
                rx="5"
              />
              <path
                d="M20.7259648,21.8456267 L20.7259648,12.9211815 L19.7121746,12.9211815 L19.7121746,21.8542953 C19.4514022,21.8528489 19.1944729,21.8473205 18.9547021,21.8347534 C18.3839093,21.8048365 17.7586148,21.6859114 17.0788185,21.4779781 C17.8880132,22.8478865 18.5383561,23.963576 19.0298471,24.8250467 C19.5213381,25.6865173 19.9365676,26.5697656 20.2755358,27.4747914 C20.1918492,27.289718 20.6011271,26.4064698 21.5033694,24.8250467 C22.4056116,23.2436236 23.0627523,22.1279341 23.4747914,21.4779781 C22.6654934,21.7158283 22.031682,21.8347534 21.5733572,21.8347534 C21.3303748,21.8347534 21.0389882,21.8403591 20.7259648,21.8456267 Z"
                id="arrow"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform="translate(20.276805, 20.197986) rotate(-90.000000) translate(-20.276805, -20.197986) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
