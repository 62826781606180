import styleVariables from './styleVariables';
import Scrollchor from 'react-scrollchor';
import styled from 'styled-components';

export const ScrollWhite = styled(Scrollchor)`
  color: ${styleVariables.white};

  &:hover {
    color: ${styleVariables.white};
  }
`;

export const ScrollBlack = styled(Scrollchor)`
  color: ${styleVariables.black};

  &:hover {
    color: ${styleVariables.black};
  }
`;
