import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FooterStyle from './FooterStyle';
import logoBlack from '../assets/svg/logo_black.svg';
import routes from './constants/routes';

function Footer() {
  return (
    <FooterStyle>
      <div className="oliverbeer-container">
        <div className="footer-line" />
        <div className="footer-container">
          <Link to="/">
            <img src={logoBlack} alt="Zimmerei Beer Logo" />
          </Link>
          <Row>
            <Col md={4}>
              <div className="footer-container-items">
                <div className="footer-headings">Zimmerei Oliver Beer</div>
                <div className="footer-texts">
                  Der Bregenzerwald hat über Jahrhunderte ein handwerkliches Wissen
                  entwickelt, das seinesgleichen sucht. Dafür stehen wir von der
                  Zimmerei Beer. Gleichzeitig sind wir stets offen für Innovationen,
                  um mit unserem handwerklichen Können noch großartigeres zu erbauen.
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-container-items">
                <div className="footer-headings">Kontakt</div>
                <div className="footer-texts">
                  6870 Reuthe
                  <br />
                  Hinterreuthe 306
                  <br />
                  <br />
                  T:
                  <a href="tel:+436645457667">+43 664 5457667</a>
                  <br />
                  M:
                  <a href="mailto:office@zimmerei-beer.at?subject=Projektanfrage">
                    office@zimmerei-beer.at
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-container-items">
                <div className="footer-headings">Sitemap</div>
                <div className="footer-texts">
                  {routes.map(route => (
                    <Link key={route.path} to={route.path} title={route.name}>
                      {route.name}
                      <br />
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="footer-line" />
        <div className="footer-copyright">
          &copy; 2020 Zimmerei Beer
          <Link to="/impressum">Impressum</Link>
        </div>
      </div>
    </FooterStyle>
  );
}

export default Footer;
