import React from 'react';
import ProjectImageSection from './components/ProjectImageSection';
import MoreProjects from './components/MorProjects';
import ProjectStyle from './ProjectStyle';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_PROJECTS } from '../constants/queries';
import LoadingIndicator from '../constants/LoadingIndicator';
import { useParams } from 'react-router-dom';
import ProjectInfoSection from './components/ProjectInfoSection';
import ProjectImageSliderSection from './components/ProjectImageSliderSection';
import { RichText } from 'prismic-dom';

function Project() {
  const { loading, data, error } = useQuery(QUERY_PROJECTS);
  const { projectId } = useParams();

  if (error) {
    return <div>Error</div>;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const projects = data.posts.edges.filter(project => {
    return project.node.meta.uid === projectId;
  });
  if (projects.length === 0) {
    return <div>Projekt nicht gefunden!</div>;
  }
  const project = projects[0].node;

  const moreProjects = data.posts.edges.filter(p => {
    return (
      p.node.projekttyp === project.projekttyp &&
      RichText.asText(p.node.titel) !== RichText.asText(project.titel)
    );
  });

  return (
    <ProjectStyle>
      <ProjectImageSection project={project} />
      <ProjectInfoSection project={project} />
      <ProjectImageSliderSection project={project} />
      <MoreProjects projects={moreProjects} />
    </ProjectStyle>
  );
}

export default Project;
