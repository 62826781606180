import React from 'react';
import { ArrowRightWhite } from '../../constants/svg';
import { RichText } from 'prismic-dom';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

function MoreProjects({ projects }) {
  return (
    <section id="project-more-projects-section">
      <div className="oliverbeer-container">
        <div className="project-sub-heading">Ähnliche Projekte</div>
        <Row>
          {projects.slice(0, 3).map(project => (
            <Col lg={4} key={project.node.meta.uid}>
              <Link to={`/projekte/${project.node.meta.uid}`}>
                <div className="project-container project-detail-page image-container">
                  <img
                    src={project.node.bilder[0].bild.url}
                    alt={RichText.asText(project.node.titel)}
                  />

                  <div className="project-container-shadow project-container-shadow-top"></div>
                  <div className="project-container-shadow project-container-shadow-bottom"></div>
                  <label>Projekte - {project.node.projekttyp}</label>

                  <h3>{RichText.asText(project.node.titel)}</h3>

                  <div className="inpage-link projects-item-link">
                    Mehr zu diesem projekt
                    <ArrowRightWhite />
                    <hr />
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default MoreProjects;
