import React from 'react';
import { RichText } from 'prismic-dom';

function ProjectImageSection({ project }) {
  return (
    <section className="project-img-section">
      <div className="project-img-section-main image-container">
        <img src={project.bilder[0].bild.url} alt={RichText.asText(project.titel)} />
        <div>{RichText.asText(project.titel)}</div>
      </div>
      <div className="project-img-section-scroll">
        Scrollen für weitere Infos und Bilder
      </div>
    </section>
  );
}

export default ProjectImageSection;
