import React from 'react';
import { Col, Row } from 'react-bootstrap';
import altbausanierungImage from '../../../assets/img/services/altbausanierung.jpg';
import fassadenImage from '../../../assets/img/services/fassaden.jpg';

function ServicesMainSection2() {
  return (
    <section className="services-main-section">
      <div className="oliverbeer-container">
        <Row>
          <Col md={6}>
            <div className="services-main-item" id="services-main-section-3">
              <div className="services-main-item-img image-container">
                <img src={altbausanierungImage} alt="Altbausanierung" />
              </div>
              <div className="services-main-item-rectangle" />
              <h2>Altbausanierung</h2>
              <p>
                Auch ein Haus braucht im Alter Aufmerksamkeit und Pflege. Wir
                verstehen uns darauf, Tradition zu bewahren und mit Modernem zu
                verbinden. So wird Ihr Altbau in neuem Glanz erstrahlen.
              </p>
              <div className="services-main-item-number">03</div>
            </div>
          </Col>
          <Col md={6}>
            <div className="services-main-item" id="services-main-section-4">
              <div className="services-main-item-img image-container">
                <img src={fassadenImage} alt="Fassaden" />
              </div>
              <div className="services-main-item-rectangle" />
              <h2>Fassaden</h2>
              <p>
                Holzfassaden sparen Energiekosten und können auch nachträglich an der
                Außenwand montiert werden. Außerdem bieten wir Fassaden aus
                verschiedenen Plattenwerkstoffen an.
              </p>
              <div className="services-main-item-number">04</div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ServicesMainSection2;
