import styled from 'styled-components';
import styleVariables from './components/constants/styleVariables';

const GlobalStyle = styled.div`
  a,
  a:focus,
  a:hover,
  a:visited {
    text-decoration: none;
  }

  .oliverbeer-container {
    width: 84vw;
    margin: 0 auto;
    max-width: 1440px;
  }

  .oliverbeer-container-without-maxwidth {
    width: 84vw;
    margin: 0 auto;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  h1 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 900;
    color: ${styleVariables.black};
  }

  h2 {
    font-size: 42px;
    letter-spacing: 3px;
    line-height: 50px;
    color: ${styleVariables.black};
    font-weight: 900;
    text-transform: uppercase;
  }

  h3 {
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 23px;
    color: ${styleVariables.white};
    font-weight: 900;
    text-transform: uppercase;
  }

  h4 {
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    font-weight: 900;
  }

  h5 {
    font-size: 19px;
    line-height: 21px;
    font-weight: 700;
    color: ${styleVariables.black};
  }

  label {
    color: ${styleVariables.grey};
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    margin-bottom: 0;
  }

  p {
    font-size: 19px;
    line-height: 40px;
    font-weight: 700;
  }

  .inpage-link {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
    font-weight: 900;
    color: ${styleVariables.darkRed};
    text-transform: uppercase;
    transition: margin-left ease 0.2s;

    & svg {
      margin-left: 20px;
      transition: margin-left ease 0.2s;
    }

    &:hover {
      color: ${styleVariables.darkRed};

      & svg {
        margin-left: 25px;
      }
      margin-left: 3px;
    }
  }

  .global-go-down-button {
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    font-weight: 300;
    text-transform: uppercase;
    transform: rotate(90deg);
    cursor: pointer;

    & svg {
      margin-left: 16px;
    }
  }

  .image-container {
    &.alternative-object-fit {
      background-size: cover;
      background-position: center center;

      & img {
        display: none;
      }
    }
  }
  @media screen and (max-width: 900px) {
    h1 {
      font-size: 21px;
      letter-spacing: 2px;
      line-height: 29px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 29px;
    }

    h5 {
      font-size: 16px;
      line-height: 18px;
    }

    label {
      font-weight: 700;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
  }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 19px;
    }
  }

  .default-section {
    margin-top: 180px;

    & h5 {
      &:first-of-type {
        margin-top: 72px;
      }
      margin-top: 40px;
    }

    & p {
      margin-top: 8px;
      font-size: 19px;
      line-height: 27px;
      font-weight: 300;
    }
  }

  .project-container {
    cursor: pointer;
    margin-top: 32px;
    height: 315px;
    position: relative;

    &.index-projects-container {
      height: 468px;

      & h3 {
        font-size: 28px;
        letter-spacing: 2px;
        line-height: 33px;
        top: 54px;
      }

      & .projects-item-link {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 17px;
        bottom: 35px;
      }

      & .project-container-shadow {
        height: 145px;
      }

      &:hover {
        & .project-container-shadow {
          height: 200px;
        }
      }
    }

    & img {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & label {
      position: absolute;
      top: 24px;
      left: 24px;
    }

    & h3 {
      position: absolute;
      top: 48px;
      left: 24px;
    }

    & .projects-item-link {
      position: absolute;
      bottom: 24px;
      color: ${styleVariables.white};
      padding-left: 24px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0;

      & hr {
        background-color: ${styleVariables.white};
        height: 2px;
        margin: 8px 0 0;
        width: 0;
        transition: width ease ${styleVariables.defaultTransitionDuration};
      }

      &:hover hr {
        width: 100%;
      }
    }

    & .project-container-shadow {
      position: absolute;
      height: 100px;
      width: 100%;
      z-index: 0;
      transition: height ease 0.2s;

      &.project-container-shadow-top {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        transform: rotate(180deg);
        top: 0;
      }

      &.project-container-shadow-bottom {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        bottom: 0;
      }
    }

    &:hover {
      & .project-container-shadow {
        height: 150px;
      }
    }
  }
`;

export default GlobalStyle;
