const styleVariables = {
  headerHeight: '100px',
  defaultTransitionDuration: '.2s',
  darkRed: '#B4181E',
  white: '#FFFFFF',
  black: '#333333',
  grey: '#CCCCCC',
  lightGrey: '#F7F7F7',
};

export default styleVariables;
