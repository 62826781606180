import styled from 'styled-components';
import styleVariables from './constants/styleVariables';

const FooterStyle = styled.footer`
  margin: 114px 0 80px 0;
  & .footer-container {
    margin: 48px 0;
    & img {
      width: 200px;
    }
    & .footer-container-items {
      margin-top: 32px;
      & .footer-headings {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 17px;
        font-weight: 900;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      & .footer-texts {
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        & a {
          color: ${styleVariables.black};
          text-decoration: underline;
        }
      }
    }
  }
  & .footer-line {
    background-color: ${styleVariables.grey};
    height: 2px;
    width: 100%;
    opacity: 0.2;
  }
  & .footer-copyright {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
    font-weight: 300;
    margin-top: 16px;
    & a {
      margin-left: 56px;
      color: ${styleVariables.black};
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    & .footer-container {
      text-align: center;
      margin: 24px 0;
      & img {
        width: 150px;
      }
      & .col-md-3:first-of-type {
        display: none;
      }
    }
    & .footer-copyright {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      & a {
        display: block;
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
`;

export default FooterStyle;
