import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_ABOUT_US } from '../../constants/queries';
import { ArrowImageGalleryLeft, ArrowImageGalleryRight } from '../../constants/svg';
import LoadingIndicator from '../../constants/LoadingIndicator';

function AboutImageSection() {
  const { loading, data, error } = useQuery(QUERY_ABOUT_US);
  const [active, setActive] = useState(0);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const images = data.aboutUs.edges[0].node.bilder;
  return (
    <section id="aboutus-intro-section">
      <div className="oliverbeer-container">
        <div className="aboutus-intro-container">
          <h2>Tradition & Innovation</h2>
          <p>
            dies haben wir uns als Leitspruch gesetzt. Unser Ziel ist es sowohl die
            moderne Architektur als auch die traditionelle Bauweise mit dem Baustoff
            Holz in ein Gebäude umzusetzen.
          </p>
          <div className="aboutus-intro-line" />
          <div className="aboutus-images-gallery-container">
            <div
              className="aboutus-images-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
              style={{ left: `-${active * 100}%` }}
            >
              {images.slice(0, 9).map((image, index) => (
                <figure
                  key={index}
                  itemProp="associatedMedia"
                  itemScope="itemScope"
                  itemType="http://schema.org/ImageObject"
                  className={
                    active === index ? 'image-container active' : 'image-container'
                  }
                >
                  <img
                    src={image.bild.url}
                    itemProp="thumbnail"
                    alt={`Description ${index}`}
                  />
                </figure>
              ))}
            </div>

            <div className="aboutus-images-gallery-legend">
              {images.map((image, index) => (
                <div key={index} className={active === index ? 'active' : ''} />
              ))}
            </div>

            <div className="aboutus-images-gallery-controls">
              <div
                className={active === 0 ? 'disabled' : ''}
                onClick={() => setActive(active - 1)}
              >
                <ArrowImageGalleryLeft />
              </div>
              <div
                className={active === images.length - 1 ? 'disabled' : ''}
                onClick={() => setActive(active + 1)}
              >
                <ArrowImageGalleryRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutImageSection;
