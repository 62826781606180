import React from 'react';
import { QUER_IMPRESSUM } from '../constants/queries';
import { useQuery } from '@apollo/react-hooks';
import LoadingIndicator from '../constants/LoadingIndicator';
import { RichText } from 'prismic-dom';

function Impressum() {
  const { loading, data, error } = useQuery(QUER_IMPRESSUM);

  if (error) {
    return <div>Error</div>;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const impressum = data.impressum.edges[0].node;
  console.log(impressum);
  return (
    <section className="default-section">
      <div className="oliverbeer-container">
        <h2>{RichText.asText(impressum.titel)}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: RichText.asHtml(impressum.inhalt),
          }}
        />
      </div>
    </section>
  );
}

export default Impressum;
