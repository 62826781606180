import styled from 'styled-components';
import styleVariables from '../constants/styleVariables';

const ProjectStyle = styled.footer`
  .project-img-section {
    height: 100vh;
    padding-top: ${styleVariables.headerHeight};

    & .project-img-section-main {
      height: calc(100vh - 2 * ${styleVariables.headerHeight});
      background-color: ${styleVariables.black};
      position: relative;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0.8;
      }

      & div {
        position: absolute;
        top: calc(((100vh - 2 * ${styleVariables.headerHeight}) / 2) - 35px);
        font-size: 64px;
        line-height: 77px;
        font-weight: 900;
        color: ${styleVariables.white};
        text-align: center;
        width: 100vw;
      }
    }

    & .project-img-section-scroll {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      padding-top: calc(${styleVariables.headerHeight} / 2);
    }
  }

  .project-info-section {
    margin-top: 48px;

    & .project-info-container {
      position: relative;
      height: 465px;

      & .project-info-content {
        position: absolute;
        top: 84px;
        left: 144px;
        right: 300px;

        & p {
          font-size: 19px;
          line-height: 28px;
          font-weight: 300;
        }

        & .project-info-content-services {
          font-size: 19px;
          line-height: 28px;
          font-weight: 300;
        }
      }

      & #project-info-to-images {
        bottom: 72px;
        right: -72px;
        transition: bottom ease ${styleVariables.defaultTransitionDuration};

        &:hover {
          bottom: 67px;
        }
      }
    }
  }

  #project-images-section {
    margin-top: 160px;

    & .project-images-container {
      position: relative;
      height: 475px;

      & .project-images-gallery-container {
        position: absolute;
        top: 100px;
        left: 8px;
        right: 163px;
        bottom: 32px;
        overflow: hidden;

        & .project-images-gallery {
          width: 1000%;
          position: absolute;
          left: 0;
          transition: left ease 0.5s;

          & figure {
            left: -2.5%;
            width: 2.5%;
            height: 160px;
            float: left;
            margin-top: 40px;
            opacity: 0.4;
            transition: opacity ease 0.5s, width ease 0.5s, margin ease 0.5s,
              height ease 0.5s;

            &.active {
              opacity: 1;
              width: 4%;
              margin: 0 0.5%;
              height: 240px;
            }

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        & .project-images-gallery-legend {
          position: absolute;
          bottom: 72px;
          left: 50%;
          transform: translateX(-50%);

          & div {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: ${styleVariables.black};
            opacity: 0.2;
            float: left;
            margin-left: 8px;
            border-radius: 2px;
            transition: opacity ease ${styleVariables.defaultTransitionDuration};

            &:first-of-type {
              margin-left: 0;
            }

            &.active {
              opacity: 1;
            }
          }
        }

        & .project-images-gallery-controls {
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px);

          & div {
            float: left;
            cursor: pointer;
            transition: transform ease ${styleVariables.defaultTransitionDuration};

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }

            &:hover {
              transform: scale(1.1);
            }

            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }

      & #project-images-to-more-projects {
        bottom: 72px;
        right: -72px;
        transition: bottom ease ${styleVariables.defaultTransitionDuration};

        &:hover {
          bottom: 67px;
        }
      }
    }
  }

  .project-sub-heading {
    font-size: 32px;
    letter-spacing: 2px;
    line-height: 38px;
    color: ${styleVariables.black};
    font-weight: 900;
    text-transform: uppercase;
  }

  .project-rectangle {
    position: absolute;
    z-index: -1;
    top: 14px;
    left: 64px;
    right: 224px;
    bottom: 0;
    border: 10px solid #f7f7f7;
  }

  #project-more-projects-section {
    margin-top: 116px;
  }

  .pswp__zoom-wrap {
    & img {
      object-fit: contain;
    }
  }
  @media screen and (max-width: 900px) {
    .project-img-section {
      & .project-img-section-main {
        & div {
          font-size: 32px;
          line-height: 38px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .project-info-section {
      & .project-info-container {
        height: auto;

        & .project-info-content {
          left: 0;
          right: 0;
          position: inherit;
          top: 32px;

          & p {
            font-size: 16px;
            line-height: 22px;
          }

          & .project-info-content-services {
            font-size: 16px;
            line-height: 22px;
          }
        }

        & #project-info-to-images {
          display: none;
        }
      }
    }

    #project-images-section {
      margin-top: 80px;

      & .project-images-container {
        height: 440px;

        & .project-images-gallery-container {
          left: 0;
          right: 0;
          overflow: hidden;

          & .project-images-gallery {
            width: 1000%;
            position: absolute;
            left: 0;
            transition: left ease 0.5s;

            & figure {
              left: -10%;
              width: 0;
              opacity: 0;

              &.active {
                opacity: 1;
                width: 10%;
                margin: 0;
              }
            }
          }

          & .project-images-gallery-legend {
            bottom: 24px;
          }

          & .project-images-gallery-controls {
            width: 100%;
            left: 0;

            & div {
              position: absolute;
              bottom: 0;

              &:first-of-type {
                margin-right: 0;
                left: 5px;
              }

              &:last-of-type {
                right: 5px;
              }
            }
          }
        }

        & #project-images-to-more-projects {
          display: none;
        }
      }
    }

    .project-sub-heading {
      font-size: 24px;
      line-height: 27px;
    }

    .project-rectangle {
      display: none;
    }
  }
`;

export default ProjectStyle;
