import { RichText } from 'prismic-dom';
import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { ArrowRightRed, ArrowRightWhite } from '../../constants/svg';
import { QUERY_PROJECTS } from '../../constants/queries';
import LoadingIndicator from '../../constants/LoadingIndicator';

function Projects() {
  const { loading, data, error } = useQuery(QUERY_PROJECTS);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <section className="index-projects-section">
      <div className="oliverbeer-container">
        <h1 className="index-h1">Projekte</h1>
        <Row>
          {data.posts.edges.slice(0, 3).map(project => (
            <Col lg={6} key={project.node.meta.uid}>
              <Link to={`/projekte/${project.node.meta.uid}`}>
                <div className="project-container index-projects-container image-container">
                  <img
                    src={project.node.bilder[0].bild.url}
                    alt={RichText.asText(project.node.titel)}
                  />
                  <div className="project-container-shadow project-container-shadow-top" />
                  <div className="project-container-shadow project-container-shadow-bottom" />
                  <label>Projekte -{project.node.projekttyp}</label>
                  <h3>{RichText.asText(project.node.titel)}</h3>
                  <div className="inpage-link projects-item-link">
                    Mehr zu diesem projekt
                    <ArrowRightWhite />
                    <hr />
                  </div>
                </div>
              </Link>
            </Col>
          ))}

          <Col lg={6}>
            <div className="index-projects-links">
              <div className="index-projects-links-item">
                <h4>Sie wollen mehr Projekte sehen?</h4>
                <div className="index-projects-links-item-link">
                  <div>
                    <Link className="inpage-link" to="/projekte">
                      Zu allen Projekten
                      <ArrowRightRed />
                      <hr />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="index-projects-links-item">
                <h4>Sie wollen mehr über unsere Leistungen wissen?</h4>
                <div className="index-projects-links-item-link">
                  <div>
                    <Link className="inpage-link" to="/leistungen">
                      Zu den Leistungen
                      <ArrowRightRed />
                      <hr />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Projects;
