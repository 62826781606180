const routes = [
  {
    name: 'Projekte',
    path: '/projekte',
  },
  {
    name: 'Leistungen',
    path: '/leistungen',
  },
  {
    name: 'Über uns',
    path: 'ueber-uns',
  },
];

export default routes;
