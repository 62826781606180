import React from 'react';
import { Col, Row } from 'react-bootstrap';
import servicesImage from '../../../assets/img/services_background.jpg';
import { ScrollBlack } from '../../constants/styleComponents';

function ServicesHeaderSection() {
  return (
    <section className="services-top-section">
      <div className="services-top-img-container image-container">
        <img src={servicesImage} alt="Werkzeuge" />
      </div>
      <div className="service-top-info-container">
        <label>Was wir für Sie machen können</label>
        <h1>Services</h1>
        <div className="service-top-info-line" />
        <Row>
          <Col md={3}>
            <ScrollBlack
              className="services-top-info-items"
              to="services-main-section-1"
              animate={{ offset: -150 }}
            >
              <div>01</div>
              Wohnbau
            </ScrollBlack>
          </Col>
          <Col md={3}>
            <ScrollBlack
              className="services-top-info-items"
              to="services-main-section-2"
              animate={{ offset: -150 }}
            >
              <div>02</div>
              Gewerbebau
            </ScrollBlack>
          </Col>
          <Col md={3}>
            <ScrollBlack
              className="services-top-info-items"
              to="services-main-section-3"
              animate={{ offset: -150 }}
            >
              <div>03</div>
              Altbausanierung
            </ScrollBlack>
          </Col>
          <Col md={3}>
            <ScrollBlack
              className="services-top-info-items"
              to="services-main-section-4"
              animate={{ offset: -150 }}
            >
              <div>04</div>
              Fassaden
            </ScrollBlack>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ServicesHeaderSection;
