import gql from 'graphql-tag';

export const QUERY_PROJECTS = gql(`
query getBlogPosts {
  posts: allBlog_posts(sortBy: meta_lastPublicationDate_DESC){
    edges {
      node{
        meta: _meta {
          id
          uid
        }
        titel
        bauzeit
        bauherr
        planung
        projekttyp
        leistungen
        bilder {
          bild
        }
      }
    }
  }
}
`);

export const QUERY_ABOUT_US = gql(`
query getAboutUs {
  aboutUs: allUber_unss(sortBy: meta_lastPublicationDate_DESC){
    edges {
      node{
        meta: _meta {
          id
          uid
        }
        bilder {
          bild
        }
      }
    }
  }
}
`);

export const QUERY_EMPLOYEES = gql(`
query getEmployees {
  employees: allMitarbeiters(sortBy: meta_lastPublicationDate_ASC) {
    edges {
      node {
        meta: _meta {
          id
          uid
        }
        name
        job_titel
        bild
      }
    }
  }
}
`);

export const QUER_IMPRESSUM = gql(`
query getImpressum {
  impressum: allImpressums {
    edges {
      node {
        _meta {
          id
        }
        titel
        inhalt
      }
    }
  }
}
`);
