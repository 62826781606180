import styled from 'styled-components';
import styleVariables from '../constants/styleVariables';

const AboutStyle = styled.div`
  .aboutus-top-section {
    position: relative;

    & .aboutus-top-img-container {
      width: 100vw;
      height: 100vh;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .aboutus-top-info-container {
      position: absolute;
      bottom: 0;
      right: 25vw;
      left: 25vw;
      width: 50vw;
      background-color: ${styleVariables.white};
      text-align: center;
      padding: 0 64px 44px;

      & label {
        margin-top: 48px;
        font-size: 12px;
        line-height: 14px;
        color: ${styleVariables.black};
      }

      h1 {
        margin-top: 16px;
        font-size: 64px;
        letter-spacing: 4px;
        line-height: 77px;
        color: ${styleVariables.darkRed};
        text-transform: uppercase;
      }

      & .aboutus-top-info-line {
        opacity: 0.3;
        width: 1px;
        background-color: ${styleVariables.black};
        height: 80px;
        margin: 24px auto 24px;
      }

      & .aboutus-top-more {
        & span {
          border-bottom: 2px solid ${styleVariables.black};
          padding-bottom: 4px;
          height: 28px;
          font-size: 19px;
          line-height: 21px;
          font-weight: 700;
          cursor: pointer;
          transition: padding-bottom ease ${styleVariables.defaultTransitionDuration};

          &:hover {
            padding-bottom: 7px;
          }
        }
      }
    }
  }

  #aboutus-intro-section {
    margin-top: 168px;

    & .aboutus-intro-container {
      position: relative;
      height: 540px;

      & p {
        padding-top: 24px;
        padding-left: 32px;
        width: 35%;
      }

      & .aboutus-intro-line {
        position: absolute;
        top: 21px;
        left: 64px;
        right: 172px;
        bottom: 180px;
        z-index: -1;
        border: 10px solid #f7f7f7;
      }

      & .aboutus-images-gallery-container {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        height: 425px;
        overflow: hidden;

        & .aboutus-images-gallery {
          width: 1000%;
          position: absolute;
          left: 0;
          transition: left ease 0.5s;

          & figure {
            left: 0;
            width: 10%;
            height: 240px;
            float: left;
            margin-top: 40px;
            opacity: 0.4;
            transition: opacity ease 0.5s, width ease 0.5s, margin ease 0.5s,
              height ease 0.5s;

            &.active {
              opacity: 1;
              margin: 0;
              height: 320px;
            }

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        & .aboutus-images-gallery-legend {
          position: absolute;
          bottom: 72px;
          left: 50%;
          transform: translateX(-50%);

          & div {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: ${styleVariables.black};
            opacity: 0.2;
            float: left;
            margin-left: 8px;
            border-radius: 2px;
            transition: opacity ease ${styleVariables.defaultTransitionDuration};

            &:first-of-type {
              margin-left: 0;
            }

            &.active {
              opacity: 1;
            }
          }
        }

        & .aboutus-images-gallery-controls {
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px);

          & div {
            float: left;
            cursor: pointer;
            transition: transform ease ${styleVariables.defaultTransitionDuration};

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }

            &:hover {
              transform: scale(1.1);
            }

            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  .aboutus-employees-section {
    margin-top: 130px;

    & .aboutus-employees-container {
      position: relative;
      padding-bottom: 134px;

      & .aboutus-employee-rectangle {
        border: 10px solid ${styleVariables.lightGrey};
        position: absolute;
        top: 22px;
        right: 0;
        left: 64px;
        bottom: 0;
        z-index: -1;
      }

      & h2 {
        padding-bottom: 48px;
      }

      & .aboutus-employee-item {
        margin-top: 56px;

        & .aboutus-employee-img {
          width: 100%;
          height: 412px;
          margin-left: 32px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        & .aboutus-employee-info {
          padding: 72px 0 0 104px;

          & h3 {
            color: ${styleVariables.black};
            text-transform: none;
            font-size: 28px;
            line-height: 33px;
            margin-top: 8px;
          }

          & p {
            margin-top: 8px;
            font-size: 19px;
            line-height: 21px;
            color: ${styleVariables.black};
          }

          & label:first-of-type {
            margin-top: 0;
          }

          & label {
            margin-top: 64px;
          }

          & label:last-of-type {
            margin-top: 32px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .aboutus-top-section {
      & .aboutus-top-info-container {
        right: 20vw;
        left: 20vw;
        width: 60vw;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .aboutus-top-section {
      & .aboutus-top-info-container {
        right: 8vw;
        left: 8vw;
        width: 84vw;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .aboutus-employees-section {
      & .aboutus-employees-container {
        text-align: center;

        & .aboutus-employee-rectangle {
          display: none;
        }

        & h2 {
          padding-bottom: 0;
        }

        & .aboutus-employee-item {
          margin-top: 64px;

          & .aboutus-employee-img {
            width: 150px;
            height: 171px;
            margin: 0 auto;
          }

          & .aboutus-employee-info {
            padding: 16px 0 0;

            & h3 {
              font-size: 18px;
              line-height: 20px;
              margin-top: 4px;
            }

            & p {
              margin-top: 4px;
              font-size: 16px;
              line-height: 19px;
            }

            & label {
              margin-top: 16px;
            }

            & label:last-of-type {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .aboutus-top-section {
      & .aboutus-top-info-container {
        padding: 0 0 44px;

        & label {
          margin-top: 26px;
          font-size: 10px;
          line-height: 12px;
        }

        h1 {
          margin-top: 16px;
          font-size: 32px;
          letter-spacing: 2px;
          line-height: 38px;
        }

        & .aboutus-top-info-line {
          height: 40px;
          margin: 16px auto 8px;
        }

        & .aboutus-top-more {
          & span {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }

    #aboutus-intro-section {
      margin-top: 100px;

      & .aboutus-intro-container {
        position: relative;
        height: unset;

        & p {
          padding-top: 16px;
          padding-left: 0;
          width: 100%;
        }

        & .aboutus-intro-line {
          display: none;
        }

        & .aboutus-images-gallery-container {
          margin-top: 48px;
          position: inherit;
          width: 100%;
          height: 290px;

          & .aboutus-images-gallery {
            & figure {
              height: 140px;

              &.active {
                height: 220px;
              }
            }
          }

          & .aboutus-images-gallery-legend {
            bottom: 24px;
          }

          & .aboutus-images-gallery-controls {
            width: 100%;
            left: 0;

            & div {
              position: absolute;
              bottom: 0;

              &:first-of-type {
                margin-right: 0;
                left: 5px;
              }

              &:last-of-type {
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutStyle;
