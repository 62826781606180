import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-dom';
import { Col, Row } from 'react-bootstrap';
import { QUERY_PROJECTS } from '../../constants/queries';
import { ArrowRightWhite, ArrowUpBlack } from '../../constants/svg';
import LoadingIndicator from '../../constants/LoadingIndicator';

const types = {
  all: 'Alle',
  wohnbau: 'Wohnbau',
  gewerbeBau: 'Gewerbebau',
  altbauSanierung: 'Altbausanierung',
  fassaden: 'Fassaden',
};

function ProjectsMainSection() {
  const { loading, data, error } = useQuery(QUERY_PROJECTS);
  const [selectedType, setSelectedType] = useState(types.all);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const projectSelectorClass = type => (selectedType === type ? 'active' : '');
  const mobileFilterClass = () => (mobileFilterOpen ? 'active' : '');

  const selectTypeMobile = type => {
    setSelectedType(type);
    setMobileFilterOpen(false);
  };

  const projects = data.posts.edges.filter(project => {
    return project.node.projekttyp === selectedType || selectedType === types.all;
  });

  return (
    <>
      <section className="projects-selector-mobile">
        <div id="projects-selector-mobile-button" className={mobileFilterClass()}>
          <ArrowUpBlack />
          <span
            id="projects-selector-mobile-button-open"
            onClick={() => setMobileFilterOpen(true)}
          >
            Filter anzeigen
          </span>
          <span
            id="projects-selector-mobile-button-close"
            onClick={() => setMobileFilterOpen(false)}
          >
            Filter schließen
          </span>
        </div>
        <div id="projects-selector-mobile-container" className={mobileFilterClass()}>
          <div>
            <label
              className={projectSelectorClass(types.all)}
              onClick={() => selectTypeMobile(types.all)}
            >
              {types.all}
            </label>
          </div>
          <div>
            <label
              className={projectSelectorClass(types.wohnbau)}
              onClick={() => selectTypeMobile(types.wohnbau)}
            >
              Nur {types.wohnbau}
            </label>
          </div>
          <div>
            <label
              className={projectSelectorClass(types.gewerbeBau)}
              onClick={() => selectTypeMobile(types.gewerbeBau)}
            >
              Nur {types.gewerbeBau}
            </label>
          </div>
          <div>
            <label
              className={projectSelectorClass(types.altbauSanierung)}
              onClick={() => selectTypeMobile(types.altbauSanierung)}
            >
              Nur {types.altbauSanierung}
            </label>
          </div>
          <div>
            <label
              className={projectSelectorClass(types.fassaden)}
              onClick={() => setSelectedType(types.fassaden)}
            >
              Nur {types.fassaden}
            </label>
          </div>
        </div>
      </section>
      <section className="projects-selector-desktop">
        <div className="oliverbeer-container">
          <label
            className={projectSelectorClass(types.all)}
            onClick={() => setSelectedType(types.all)}
          >
            {types.all}
          </label>
          <label
            className={projectSelectorClass(types.wohnbau)}
            onClick={() => setSelectedType(types.wohnbau)}
          >
            Nur {types.wohnbau}
          </label>
          <label
            className={projectSelectorClass(types.gewerbeBau)}
            onClick={() => setSelectedType(types.gewerbeBau)}
          >
            Nur {types.gewerbeBau}
          </label>
          <label
            className={projectSelectorClass(types.altbauSanierung)}
            onClick={() => setSelectedType(types.altbauSanierung)}
          >
            Nur {types.altbauSanierung}
          </label>
          <label
            className={projectSelectorClass(types.fassaden)}
            onClick={() => setSelectedType(types.fassaden)}
          >
            Nur {types.fassaden}
          </label>
        </div>
      </section>
      <section
        className="projects-projects-section"
        id="projects-all-projects-section"
      >
        <div className="oliverbeer-container">
          <Row>
            {projects.slice(0, 6).map(project => (
              <Col lg={4} key={project.node.meta.uid}>
                <Link to={`/projekte/${project.node.meta.uid}`}>
                  <div className="project-container project-detail-page image-container">
                    <img
                      src={project.node.bilder[0].bild.url}
                      alt={RichText.asText(project.node.titel)}
                    />
                    <div className="project-container-shadow project-container-shadow-top" />
                    <div className="project-container-shadow project-container-shadow-bottom" />
                    <label>Projekte -{project.node.projekttyp}</label>
                    <h3>{RichText.asText(project.node.titel)}</h3>
                    <div className="inpage-link projects-item-link">
                      Mehr zu diesem projekt
                      <ArrowRightWhite />
                      <hr />
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
}

export default ProjectsMainSection;
