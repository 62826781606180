import React from 'react';
import Projects from './components/Projects';
import LandingSection from './components/LandingSection';
import HomeStyle from './HomeStyle';
import IntroSection from './components/IntroSetion';
import PartnersSection from './components/PartnersSection';
import ContactSection from './components/ContactSection';

function Home() {
  return (
    <HomeStyle>
      <LandingSection />
      <IntroSection />
      <Projects />
      <PartnersSection />
      <ContactSection />
    </HomeStyle>
  );
}

export default Home;
