import React from 'react';
import { RichText } from 'prismic-dom';
import { ArrowRightBlack } from '../../constants/svg';
import { ScrollBlack } from '../../constants/styleComponents';

function ProjectInfoSection({ project }) {
  return (
    <section className="project-info-section">
      <div className="oliverbeer-container">
        <div className="project-info-container">
          <div className="project-sub-heading">Informationen zu diesem Projekt</div>
          <div className="project-info-content">
            <div className="row">
              <div className="col-lg-6">
                {project.bauzeit && project.bauzeit[0].text && (
                  <>
                    <h5>Bauzeit</h5>
                    <p>{RichText.asText(project.bauzeit)}</p>
                  </>
                )}
                {project.bauherr && project.bauherr[0].text && (
                  <>
                    <h5>Bauherr</h5>
                    <p>{RichText.asText(project.bauherr)}</p>
                  </>
                )}
                {project.planung > 0 && project.planung[0].text && (
                  <>
                    <h5>Planung</h5>
                    <p>{RichText.asText(project.planung)}</p>
                  </>
                )}

                <h5>Projekttyp</h5>
                <p>{project.projekttyp}</p>
              </div>
              <div className="col-lg-6">
                {project.leistungen && (
                  <>
                    <h5>Leistungen</h5>
                    <div
                      className="project-info-content-services"
                      dangerouslySetInnerHTML={{
                        __html: RichText.asHtml(project.leistungen),
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="project-rectangle"></div>

          <div id="project-info-to-images" className="global-go-down-button">
            <ScrollBlack to="project-images-section" animate={{ offset: -150 }}>
              Zu den Bildern
              <ArrowRightBlack />
            </ScrollBlack>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectInfoSection;
