import styled from 'styled-components';
import styleVariables from './constants/styleVariables';

const HeaderStyle = styled.header`
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: ${styleVariables.headerHeight};
  top: 0;
  background-color: ${styleVariables.white};
  transition: background-color ease ${styleVariables.defaultTransitionDuration},
    top ease ${styleVariables.defaultTransitionDuration};

  &.header-white {
    background-color: transparent;

    & .header-container {
      & .header-item {
        & #index-navigation {
          & .index-navigation-item {
            color: ${styleVariables.white};
          }
        }

        & .header-menu-button-open {
          & img {
            &:first-of-type {
              display: none;
            }

            &:last-of-type {
              display: inline;
            }
          }
        }
      }
    }

    &.header-white-special {
      & .header-container {
        & .header-item {
          & .header-menu-button-open {
            & img {
              &:first-of-type {
                display: inline;
              }

              &:last-of-type {
                display: none;
              }
            }
          }
        }
      }
    }

    &.menu-open {
      background-color: ${styleVariables.white};
    }
  }

  &.header-logo-white {
    & .header-container {
      & .header-item {
        & .header-logo {
          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: inline;
          }
        }
      }
    }

    &.menu-open {
      & .header-container {
        & .header-item {
          & .header-logo {
            &:first-of-type {
              display: inline;
            }

            &:last-of-type {
              display: none;
            }
          }
        }
      }
    }
  }

  & .header-container {
    margin: 0 auto;
    height: ${styleVariables.headerHeight};
    display: table;

    & .header-item {
      display: table-cell;
      vertical-align: middle;

      & .header-menu-button {
        cursor: pointer;

        &.invisible {
          display: none;
        }
      }

      & .header-menu-button-open {
        & img {
          &:first-of-type {
            display: inline;
          }

          &:last-of-type {
            display: none;
          }
        }
      }

      & .header-logo {
        width: 200px;

        &:last-of-type {
          display: none;
        }
      }

      &.header-item-small {
        display: none;
      }

      & #index-cantact-button {
        display: inline-block;
        padding: 8px 40px;
        border: 1px solid ${styleVariables.darkRed};
        color: ${styleVariables.darkRed};
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        transition: box-shadow ease ${styleVariables.defaultTransitionDuration};

        &:hover {
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }
      }

      & #index-navigation {
        width: 400px;
        display: inline;

        & .index-navigation-item {
          float: left;
          font-weight: 500;
          text-align: center;
          display: inline-block;
          width: 25%;
          color: ${styleVariables.black};
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 1px;

          &.item1.active ~ hr {
            margin-right: 75%;
            width: 25%;
          }

          &.item2.active ~ hr {
            margin-right: 50%;
            width: 25%;
          }

          &.item3.active ~ hr {
            margin-right: 25%;
            width: 25%;
          }

          &.item4.active ~ hr {
            margin-right: 0;
            width: 25%;
          }

          &.item1:hover ~ hr {
            margin-right: 75%;
            width: 25%;
          }

          &.item2:hover ~ hr {
            margin-right: 50%;
            width: 25%;
          }

          &.item3:hover ~ hr {
            margin-right: 25%;
            width: 25%;
          }

          &.item4:hover ~ hr {
            margin-right: 0;
            width: 25%;
          }
        }
      }

      & hr {
        position: static;
        border: hidden;
        margin: 25px 0 -15px auto;
        width: 0;
        transition: margin-right 0.4s ease, width 0.4s ease;
        height: 2px;
        background-color: ${styleVariables.darkRed};
      }
    }
  }

  &.slideUp {
    top: -${styleVariables.headerHeight};
  }

  &.menu-open {
    &.slideUp {
      top: 0;
    }
  }

  &.headroom--not-top {
    background-color: ${styleVariables.white};

    & .header-container {
      & .header-item {
        & #index-navigation {
          & .index-navigation-item {
            color: ${styleVariables.black};
          }
        }

        & .header-logo {
          &:first-of-type {
            display: inline;
          }

          &:last-of-type {
            display: none;
          }
        }
      }
    }

    &.header-white {
      & .header-container {
        & .header-item {
          & .header-menu-button-open {
            & img {
              &:first-of-type {
                display: inline;
              }

              &:last-of-type {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  #header-menu {
    &.active {
      opacity: 1;
      pointer-events: all;
      z-index: 0;

      & div {
        margin-top: 32px;
      }
    }
    z-index: -1000;
    transition: opacity ease ${styleVariables.defaultTransitionDuration};
    opacity: 0;
    pointer-events: none;
    width: 100vw;
    height: calc(100vh - ${styleVariables.headerHeight});
    position: absolute;
    top: ${styleVariables.headerHeight};
    left: 0;
    bottom: 0;
    background-color: ${styleVariables.white};

    & div {
      text-align: center;
      margin-top: 24px;
      transition: margin-top ease ${styleVariables.defaultTransitionDuration};

      a {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: ${styleVariables.black};
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 900px) {
    & .header-container {
      & .header-item {
        & .header-logo {
          width: 150px;
        }

        &.header-item-large {
          display: none;
        }

        &.header-item-small {
          display: table-cell;
        }
      }
    }
  }
`;

export default HeaderStyle;
