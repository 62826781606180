import React from 'react';
import styled from 'styled-components';
import loadingIcon from '../../assets/svg/loader.svg';

const StyledLoadingIndicator = styled.div`
  width: 100%;
  height: 400px;
  text-align: center;
  padding-top: 30vh;
`;

function LoadingIndicator() {
  return (
    <StyledLoadingIndicator>
      <img src={loadingIcon} alt="Loading..." />
    </StyledLoadingIndicator>
  );
}

export default LoadingIndicator;
