import React from 'react';
import { Link } from 'react-router-dom';
import traditionAndInnovation from '../../../assets/img/intro-image.jpg';
import { ArrowRightRed } from '../../constants/svg';

function IntroSection() {
  return (
    <section id="index-intro-section">
      <div className="oliverbeer-container">
        <div className="index-intro-container">
          <h1>Zimmerei Oliver Beer</h1>
          <h1>Tradition & Innovation</h1>
          <p>
            Der Bregenzerwald hat über Jahrhunderte ein handwerkliches Wissen
            entwickelt, das seinesgleichen sucht. Dafür stehen wir von der Zimmerei
            Beer. Gleichzeitig sind wir stets offen für Innovationen, um mit unserem
            handwerklichen Können noch großartigeres zu erbauen.
          </p>
          <Link to="/ueber-uns" className="inpage-link">
            Mehr über uns
            <ArrowRightRed />
            <hr />
          </Link>
          <img src={traditionAndInnovation} alt="Tradition & Innovation" />
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
