import { InMemoryCache } from 'apollo-cache-inmemory';
import { PrismicLink } from 'apollo-link-prismic';
import ApolloClient from 'apollo-client';

const graphqlEndpoint = 'https://zimmerei-beer.cdn.prismic.io/graphql';

const client = new ApolloClient({
  link: PrismicLink({
    uri: graphqlEndpoint,
  }),
  cache: new InMemoryCache(),
});

export default client;
