import React from 'react';

function ProjectsHeaderSection() {
  return (
    <section className="projects-header-section">
      <div className="oliverbeer-container">
        <h2>Projekte</h2>
        <p>
          Unser Ziel ist es, sowohl die moderne Architektur als auch die
          traditionelle Bauweise mit dem Baustoff Holz in einem Gebäude zu vereinen.
        </p>
      </div>
    </section>
  );
}

export default ProjectsHeaderSection;
