import styled from 'styled-components';
import styleVariables from '../constants/styleVariables';

const ServicesStyle = styled.div`
  .services-top-section {
    position: relative;

    & .services-top-img-container {
      width: 100vw;
      height: 100vh;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .service-top-info-container {
      position: absolute;
      bottom: 0;
      right: 25vw;
      left: 25vw;
      width: 50vw;
      background-color: ${styleVariables.white};
      text-align: center;
      padding: 0 64px 44px;

      & label {
        margin-top: 48px;
        font-size: 12px;
        line-height: 14px;
        color: ${styleVariables.black};
      }

      h1 {
        margin-top: 16px;
        font-size: 64px;
        letter-spacing: 4px;
        line-height: 77px;
        color: ${styleVariables.darkRed};
        text-transform: uppercase;
      }

      & .service-top-info-line {
        opacity: 0.3;
        width: 1px;
        background-color: ${styleVariables.black};
        height: 80px;
        margin: 24px auto 0;
      }

      & .services-top-info-items {
        cursor: pointer;
        display: inline-block;
        margin-top: 34px;
        text-align: left;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: 0;
        color: $black;
        font-weight: 700;
        position: relative;

        & div {
          transition: left ease 0.4s;
          width: 50px;
          left: 0;
          top: -10px;
          opacity: 0.1;
          position: absolute;
          font-size: 42px;
          line-height: 48px;
          color: ${styleVariables.darkRed};
        }

        &:hover {
          & div {
            left: calc(100% - 48px);
          }
        }
      }
    }
  }

  .services-main-section {
    margin-top: 140px;

    & .col-md-6 {
      &:first-of-type {
        padding-right: 80px;
      }

      &:last-of-type {
        padding-top: 120px;
        padding-left: 80px;
      }

      & .services-main-item {
        position: relative;

        &:hover {
          & .services-main-item-img img {
            opacity: 1 !important;
          }
        }

        & .services-main-item-img {
          z-index: -1;
          position: absolute;
          right: 34px;
          top: 0;
          left: 0;
          height: 298px;
          background-color: ${styleVariables.white};

          & img {
            transition: opacity ease ${styleVariables.defaultTransitionDuration};
            opacity: 0.6;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        & .services-main-item-rectangle {
          z-index: -2;
          position: absolute;
          right: 0;
          top: 48px;
          left: 34px;
          height: 280px;
          border: 10px solid ${styleVariables.lightGrey};
        }

        & h2 {
          padding-top: 354px;
          color: ${styleVariables.black};
          font-size: 28px;
          letter-spacing: 2px;
          line-height: 33px;
        }

        & p {
          margin-top: 16px;
          font-size: 19px;
          line-height: 36px;
          font-weight: 300;
          width: 70%;
        }

        & .services-main-item-number {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 64px;
          line-height: 73px;
          color: ${styleVariables.darkRed};
          opacity: 0.1;
          font-weight: 700;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .services-top-section {
      & .service-top-info-container {
        right: 20vw;
        left: 20vw;
        width: 60vw;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .services-top-section {
      & .service-top-info-container {
        right: 8vw;
        left: 8vw;
        width: 84vw;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .services-top-section {
      & .service-top-info-container {
        padding: 0 0 44px;

        & label {
          margin-top: 26px;
          font-size: 10px;
          line-height: 12px;
        }

        h1 {
          margin-top: 16px;
          font-size: 32px;
          letter-spacing: 2px;
          line-height: 38px;
        }

        & .service-top-info-line {
          height: 40px;
          margin: 16px auto 8px;
        }

        & .services-top-info-items {
          margin-top: 8px;

          & div {
            display: none;
          }
        }
      }
    }

    .services-main-section {
      margin-top: 80px;

      & .col-md-6 {
        &:first-of-type {
          padding-right: 0;
        }

        &:last-of-type {
          padding-top: 80px;
          padding-left: 0;
        }

        & .services-main-item {
          & .services-main-item-img {
            right: 0;
            height: 220px;

            & img {
              opacity: 1;
            }
          }

          & .services-main-item-rectangle {
            display: none;
          }

          & h2 {
            padding-top: 244px;
            font-size: 24px;
            letter-spacing: 1px;
            line-height: 29px;
          }

          & p {
            font-size: 16px;
            line-height: 30px;
            width: 90%;
          }

          & .services-main-item-number {
            display: none;
          }
        }
      }
    }
  }
`;

export default ServicesStyle;
